import React, { Component } from "react";

class Error extends Component {
  render() {
    return (
      <div className="container">
        <div className="content">
          <h1>404</h1>
          <p>
            Das sollte nicht passieren. Bitte überprüfe die Adresse der Website
            oder wende dich an den Administrator.
          </p>
        </div>
      </div>
    );
  }
}

export default Error;
