import React, { Component } from "react";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { ProtectedRoute } from "./components/common/router/protected";
import rootReducers from "./store/reducers";

import "./App.less";
//import "./assets/css/fontawesome-free/css/all.css";

import Error from "./pages/error";
import Login from "./pages/login";
import PDFKitIntegration from "./pages/pdfkitpage";
import DashBoard from "./pages/dashboard";
import AdminPage from "./pages/admin";
import ParticipantRegistrationForm from "./pages/participantRegistrationForm";

import { ThemeProvider } from "styled-components";
import { siteTheme } from "./components/common/theme";
import { ResetCSS } from "./assets/wienXtra/style";
import { GlobalStyle, AppWrapper } from "./assets/wienXtra/wienXtra.style";
import { ConfigProvider } from "antd";
import de_DE from "antd/es/locale/de_DE";

interface GuestRouteInterface {
  path: string;
  component: any;
  exact?: boolean;
}

const store = createStore(rootReducers, applyMiddleware(thunk));

const guestRoutes: Array<GuestRouteInterface> = [
  { path: "/pdftest", component: PDFKitIntegration, exact: true },
  { path: "/login", component: Login, exact: true },
  { path: "/", component: ParticipantRegistrationForm, exact: true },
];

const protectedRoutes: Array<any> = [
  { path: "/dashboard", component: DashBoard, exact: true },
  { path: "/admin", component: AdminPage, exact: true },
];

class App extends Component {
  render() {
    return (
      <ConfigProvider locale={de_DE}>
        <ThemeProvider theme={siteTheme}>
          <ResetCSS />
          <GlobalStyle />
          {/*         <AppWrapper> */}
          <HashRouter>
            <Switch>
              {guestRoutes.map((route, key) => {
                return (
                  <Route
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    key={key}
                  />
                );
              })}
              <Provider store={store}>
                {protectedRoutes.map((route, key) => {
                  return (
                    <ProtectedRoute
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                      key={key}
                    />
                  );
                })}
              </Provider>
              <Route component={Error} />
            </Switch>
          </HashRouter>
          {/*       </AppWrapper> */}
        </ThemeProvider>
      </ConfigProvider>
    );
  }
}

export default App;
