import Log from "../../src/Log";
import axios from "axios";
import UrlService from "./UrlService";
import CookieService from "./CookieService";
import HttpService from "./HttpService";

const expiresAt = 60 * 24;

interface MetaData {
  productionTitle: string;
  nameMainContact: string;
  emailMainContact: string;
  addressMainContact: string;
  furtherProjects: string;
  collection: string[];
}

class BackEndService {
  async getProductionsMetaData() {
    try {
      const response = await HttpService.get(
        UrlService.getProductionsMetaDataUrl()
      );
      if (response != undefined && response.data != undefined) {
        return response;
      }
    } catch (error) {
      console.error("Error", error.response);
    }
  }

  async getProductionsMetaDataSelectionAsExcel(selection: any) {
    Log.d("selection: " + selection);
    try {
      const response = await HttpService.post(
        UrlService.getProductionsMetaDataSelectionAsExcelUrl(),
        selection,
        null,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      if (response != undefined && response.data != undefined) {
        return response;
      }
    } catch (error) {
      console.error("Error", error.response);
    }
  }

  async getContacts() {
    try {
      const response = await HttpService.get(UrlService.getContactsUrl());
      if (response != undefined && response.data != undefined) {
        return response;
      }
    } catch (error) {
      console.error("Error", error.response);
    }
  }
  //precondition: accepts an array of jobject with a enable flag and a mailAddr field
  async updateContacts(addrList: any) {
    try {
      const response = await HttpService.post(
        UrlService.updateContactsUrl(),
        addrList
      );
      if (response != undefined && response.data != undefined) {
        return response;
      }
    } catch (error) {
      console.error("Error", error.response);
    }
  }

  async getApplicationPhase() {
    try {
      const response = await HttpService.get(
        UrlService.getApplicationPhaseUrl()
      );
      if (response != undefined && response.data != undefined) {
        return response;
      }
    } catch (error) {
      console.error("Error", error.response);
    }
  }
  //precondition: accepts an array of jobject with a enable flag and a mailAddr field
  async updateApplicationPhase(addrList: any) {
    try {
      const response = await HttpService.post(
        UrlService.updateApplicationPhaseUrl(),
        addrList
      );
      if (response != undefined && response.data != undefined) {
        return response;
      }
    } catch (error) {
      console.error("Error", error.response);
    }
  }

  //accepts a production id
  async deleteVideoByProduction(productionMeta: any) {
    try {
      const response = await HttpService.post(
        UrlService.deleteVideoByProductionUrl(),
        productionMeta
      );
      if (response != undefined && response.data != undefined) {
        return response;
      }
    } catch (error) {
      console.error("Error", error.response);
    }
  }

  async sanitizeStorage() {
    try {
      const response = await HttpService.get(UrlService.sanitizeStorageUrl());
      if (response != undefined && response.data != undefined) {
        return response;
      }
    } catch (error) {
      console.error("Error", error.response);
    }
  }

  async cleanUpStorage() {
    try {
      const response = await HttpService.get(UrlService.cleanUpStorageUrl());
      if (response != undefined && response.data != undefined) {
        return response;
      }
    } catch (error) {
      console.error("Error", error.response);
    }
  }
}

export default new BackEndService();
