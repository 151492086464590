import Log from "../../../../../src/Log";
import React, { useState, useRef, useEffect } from "react";
import Fade from "react-reveal/Fade";
import ScrollSpyMenu from "../../reusable/ScrollSpyMenu";
import Scrollspy from "react-scrollspy";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Icon } from "react-icons-kit";
import { menu } from "react-icons-kit/feather/menu";
import { x } from "react-icons-kit/feather/x";
import { search } from "react-icons-kit/feather/search";
import Logo from "../../reusable/UI/Logo";
import Button from "../../reusable/Button";
import Container from "../Container";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import NavbarWrapper, { MenuArea, MobileMenu, Search } from "./navbar.style";
import LogoImage from "../../../../assets/wienXtra/img/WIENXTRA_wortmarke_web.svg";
import LogoImageAlt from "../../../../assets/wienXtra/img/WIENXTRA_wortmarke_web.svg";
import logo from "../../../../assets/wienXtra/img/WIENXTRA_wortmarke_web.svg";
import Link from "../../reusable/Link";
import Auth from "../../router/protected/Auth";
import { LogoutOutlined } from "@ant-design/icons";
import { Link as ReactLink } from "react-router-dom";

const linkPreFix = "/#";

const navbarLoggedIn = {
  logo: logo,
  navMenu: [
    {
      id: 3,
      label: "Dashboard",
      path: linkPreFix + "/dashboard",
      offset: "0",
      staticLink: true,
      target: "_self",
    },
  ],
};

const navbarLoggedOut = {
  logo: logo,
  navMenu: [
    {
      id: 1,
      label: "Video und Filmtage",
      path: "https://www.videoundfilmtage.at/",
      offset: "0",
      staticLink: true,
      target: "_blank",
    },
    {
      id: 2,
      label: "Impressum",
      path: "https://www.wienxtra.at/impressum",
      offset: "0",
      staticLink: true,
      target: "_blank",
    },
    {
      id: 2,
      label: "Datenschutz",
      path: "https://www.wienxtra.at/datenschutz",
      offset: "0",
      staticLink: true,
      target: "_blank",
    },
  ],
};

const Navbar = (props) => {
  const auth = new Auth();

  const [navMenu, setNavMenu] = useState(navbarLoggedOut);
  const [scrollItems, setScrollItems] = useState(undefined);

  useEffect(() => {
    if (auth.isAuthenticated()) {
      setNavMenu(navbarLoggedIn);
    } else {
      setNavMenu(navbarLoggedOut);
    }

    let tempScrollItems = [];
    navMenu.navMenu.forEach((item) => {
      tempScrollItems.push(item.path.slice(1));
    });

    setScrollItems(tempScrollItems);
  }, [navMenu]);

  const [state, setState] = useState({
    search: "",
    searchToggle: false,
    mobileMenu: false,
  });

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = (type) => {
    if (type === "search") {
      setState({
        ...state,
        search: "",
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      });
    }

    if (type === "menu") {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  const handleOnChange = (event) => {
    setState({
      ...state,
      search: event.target.value,
    });
  };

  const handleSearchForm = (event) => {
    event.preventDefault();

    if (state.search !== "") {
      Log.d("search data: ", state.search);

      setState({
        ...state,
        search: "",
      });
    } else {
      Log.d("Please fill this field.");
    }
  };

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="Video und Filmtage"
          className="main-logo"
        />
        <Logo
          href="/"
          logoSrc={LogoImageAlt}
          title="Video und Filmtage"
          className="logo-alt"
        />
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? "active" : ""}>
          {auth.isAuthenticated() ? (
            <></>
          ) : (
            <>
              <ReactLink to="/">
                <Button
                  className="trail"
                  title="Start"
                  style={{ marginRight: 30 }}
                />
              </ReactLink>
            </>
          )}

          {navMenu !== undefined && scrollItems !== undefined ? (
            <>
              <ScrollSpyMenu
                className="menu"
                menuItems={navMenu.navMenu}
                offset={-84}
              />
            </>
          ) : (
            <></>
          )}

          {auth.isAuthenticated() ? (
            <>
              <ReactLink to="/">
                <Button
                  className="trail"
                  title="Abmelden"
                  style={{ marginLeft: 10 }}
                  onClick={auth.logout}
                />
              </ReactLink>
            </>
          ) : (
            <>
              <ReactLink to="/login">
                <Button
                  className="trail"
                  title="Admin"
                  style={{ marginLeft: 10 }}
                />
              </ReactLink>
            </>
          )}

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            variant="textButton"
            onClick={() => toggleHandler("menu")}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? "active" : ""}`}>
        <Container>
          {auth.isAuthenticated() ? (
            <></>
          ) : (
            <>
              <ReactLink to="/">
                <Button
                  className="trail"
                  title="Start"
                  style={{ marginRight: 30 }}
                />
              </ReactLink>
            </>
          )}

          {navMenu !== undefined && scrollItems !== undefined ? (
            <>
              <Scrollspy
                className="menu"
                items={scrollItems}
                offset={-84}
                currentClassName="active"
              >
                {navMenu.navMenu.map((menu, index) => (
                  <li key={`menu_key${index}`}>
                    <Link
                      href={menu.path}
                      offset={menu.offset}
                      onClick={handleRemoveMenu}
                    >
                      {menu.label}
                    </Link>
                  </li>
                ))}
              </Scrollspy>
            </>
          ) : (
            <></>
          )}

          {auth.isAuthenticated() ? (
            <>
              <ReactLink to="/">
                <Button title="Abmelden" onClick={auth.logout} />
              </ReactLink>
            </>
          ) : (
            <>
              <ReactLink to="/login">
                <Button title="Admin" />
              </ReactLink>
            </>
          )}
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
