import AuthService from "../../../../services/AuthService";
import CookieService from "../../../../services/CookieService";

class Auth {
  private authenticated: boolean = false;

  constructor() {
    const token = CookieService.get("access_token");
    token ? (this.authenticated = true) : (this.authenticated = false);
  }

  async login(credentials, callBack) {
    const user = await AuthService.doUserLogin(credentials);

    if (!user) {
      callBack(false);
      return false;
    }

    localStorage.setItem("accessToken", user.access_token);
    this.authenticated = true;
    callBack(true);
  }

  logout(callBack) {
    CookieService.remove("access_token");
    if (this !== undefined) {
      this.authenticated = false;
      callBack();
    }
  }

  isAuthenticated() {
    return this.authenticated;
  }

  getAccessToken() {
    return CookieService.get("access_token");
  }
}

export default Auth;
