import Log from "../../src/Log";
import axios from "axios";
import UrlService from "./UrlService";
import CookieService from "./CookieService";
import HttpService from "./HttpService";

const expiresAt = 60 * 24;

interface MetaData {
  productionTitle: string;
  nameMainContact: string;
  emailMainContact: string;
  addressMainContact: string;
  furtherProjects: string;
  collection: string[];
}

const headers = {
  "Content-Type": "application/json",
};

class FrontEndService {
  async sendMail(metaData: MetaData) {
    try {
      const response = await axios.post(UrlService.sendMailUrl(), metaData, {
        headers: headers,
      });

      Log.d("RESPONSE FROM MAIL: " + JSON.stringify(response));
      return { success: true, data: response.status };
    } catch (error) {
      console.error("Error", error.response);
      return { success: false, data: error.response };
    }
  }

  async uploadMetaData(metaData: MetaData) {
    try {
      const response = await axios.post(
        UrlService.uploadMetaDataUrl(),
        metaData,
        { headers: headers }
      );
      //Log.d("response from uploadMetaData: " + JSON.stringify(response));
      const jsonResponse = JSON.parse(response.data[0]);
      Log.d(
        "jsonResponse from uploadMetaData: " + JSON.stringify(jsonResponse)
      );
      return { success: jsonResponse.success, data: jsonResponse.data };
    } catch (error) {
      console.error("Error", error.response);
      return { success: false, data: error.response };
    }
  }
  handleUploadSuccess(response: any, remember: boolean) {
    //TODO i dont think we need this here
    if (!remember) {
      const options = { path: "/" };
      CookieService.set("access_token", response.access_token, options);
      return true;
    }

    let date = new Date();
    date.setTime(date.getTime() + expiresAt * 60 * 1000);
    const options = { path: "/", expires: date };
    CookieService.set("access_token", response.access_token, options);
    return true;
  }
}

export default new FrontEndService();
