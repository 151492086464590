import Log from "../../src/Log";
import axios from "axios";
import UrlService from "./UrlService";
import CookieService from "./CookieService";
const path = require("path");
const envPath = path.resolve(__dirname, "client/.env");
require("dotenv").config({ path: envPath });

const expiresAt = 60 * 24;

interface Credentials {
  username: string;
  password: string;
}

const headers = {
  "Content-Type": "application/json",
  apiKey: process.env.REACT_APP_BACKENDAPIKEY,
};

class AuthService {
  async doLDAPUserLogin(credentials: Credentials) {
    Log.d(
      " HEADERS: " +
        process.env.REACT_APP_BACKENDAPIKEY +
        " " +
        JSON.stringify(headers)
    );

    Log.d("envpath: " + envPath);

    try {
      const response = await axios.post(
        UrlService.loginLDAPUrl(),
        credentials,
        {
          headers: headers,
        }
      );
      Log.d("doLDAPUserLogin Resp.: " + JSON.stringify(response.data));
      const jsonResponse = JSON.parse(response.data[0]);
      return {
        success: jsonResponse.success,
        access_token: jsonResponse.access_token,
      };
    } catch (error) {
      console.error("Error", error.response);
      return false;
    }
  }

  async doConventialUserLogin(credentials: Credentials) {
    try {
      const response = await axios.post(UrlService.loginUrl(), credentials);
      return response.data;
    } catch (error) {
      console.error("Error", error.response);
      return false;
    }
  }

  async doUserLogin(credentials: Credentials, ldap: boolean = true) {
    try {
      if (ldap) {
        return this.doLDAPUserLogin(credentials);
      } else {
        return this.doConventialUserLogin(credentials);
      }
    } catch (error) {
      console.error("Error", error.response);
      return false;
    }
  }

  handleLoginSuccess(response: any, remember: boolean) {
    if (!remember) {
      const options = { path: "/" };
      CookieService.set("access_token", response.access_token, options);
      return true;
    }

    let date = new Date();
    date.setTime(date.getTime() + expiresAt * 60 * 1000);
    const options = { path: "/", expires: date };
    CookieService.set("access_token", response.access_token, options);
    return true;
  }
}

export default new AuthService();
