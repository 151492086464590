import React, { useState } from "react";
import { CustomLayout } from "../../components/common/custom/CustomLayout";
import DashBoardTable from "./DashBoardTable";
import Colors from "./../../components/common/theme/colors";

const primaryColor = Colors.primary;
const secondaryColor = Colors.secondary;

const DashBoard = () => {
  //optional if we need ot set something here triggered by child
  const [tableData, setTableData] = useState([]);

  const onTableUpdate = (data) => {
    setTableData(data);
  };

  return (
    <CustomLayout>
      <DashBoardTable onTableUpdate={onTableUpdate} />
    </CustomLayout>
  );
};

export default DashBoard;
