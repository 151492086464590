export default class Log {
  public static debugLog: boolean = true;
  public static errorLog: boolean = true;

  public static d(msg: string) {
    if (Log.debugLog) {
      console.log(msg);
    }
  }

  public static e(msg: string) {
    if (Log.errorLog) {
      console.error(msg);
    }
  }
}
