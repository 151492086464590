const path = require("path");
const envPath = path.resolve(__dirname, "client/.env");
require("dotenv").config({ path: envPath });

const apiDomain = "/server/";//process.env.PUBLIC_URL;

class UrlService {

  static storageUrl() {
    return apiDomain + "productions/";
  }
  static loginUrl() {
    return apiDomain + "api/login";
  }
  static loginLDAPUrl() {
    return apiDomain + "api/login_ldap";
  }
  static currentUserProfileUrl() {
    return apiDomain + "api/user";
  }
  static saveUserProfileUrl() {
    return apiDomain + "api/user";
  }
  static getCurrentUserAcitiviesUrl() {
    return apiDomain + "api/activities";
  }
  static uploadUrl() {
    return apiDomain + "api/upload";
  }
  static sendMailUrl() {
    return apiDomain + "api/sendMail";
  }
  static uploadMetaDataUrl() {
    return apiDomain + "api/uploadParticipantMetaData";
  }
  static getProductionsMetaDataUrl() {
    return apiDomain + "api/getProductionsMetaData";
  }
  static getProductionsMetaDataSelectionAsExcelUrl() {
    return apiDomain + "api/getProductionsMetaDataSelectionAsExcel";
  }
  static getContactsUrl() {
    return apiDomain + "api/getContacts";
  }
  static updateContactsUrl() {
    return apiDomain + "api/updateContacts";
  }
  static getApplicationPhaseUrl() {
    return apiDomain + "api/getApplicationPhase";
  }
  static updateApplicationPhaseUrl() {
    return apiDomain + "api/updateApplicationPhase";
  }
  static deleteVideoByProductionUrl() {
    return apiDomain + "api/deleteVideoByProduction";
  }
  static deleteAllDataByProductionUrl() {
    return apiDomain + "api/deleteAllDataByProduction";
  }
  static streamVideoUrl() {
    return apiDomain + "api/streamVideo";
  }
  static sanitizeStorageUrl() {
    return apiDomain + "api/sanitizeStorage";
  }
  static cleanUpStorageUrl() {
    return apiDomain + "api/cleanUpStorage";
  }
}

export default UrlService;
