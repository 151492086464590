import React, { useCallback, useState } from "react";
import { CustomLayout } from "../../components/common/custom/CustomLayout";

//Upload
import ChunkedUploady, { useChunkFinishListener } from "@rpldy/chunked-uploady";
import UrlService from "../../services/UrlService";
import ParticipantRegistrationForm from "./ParticipantRegistrationForm";
import { CookiesProvider } from "react-cookie";
import { notification } from "antd";

const Index = () => {
  var isImageSet: Boolean = false;
  var isVideoSet: Boolean = false;

  const openNotificationWithIcon = (type, file) => {
    if (type == "error") {
      notification[type]({
        message: "Fehler",
        description: `Mit der Datei ${file.name} stimmt etwas nicht. Bitte beachte die beim Upload Feld beschriebenen Einschränkungen.`,
      });
    } else if (type == "success") {
      notification[type]({
        message: "OK",
        description: `Datei ${file.name} hat ein unterstütztes Format und die richtige Größe. Upload startet.`,
      });
    }
  };

  const filterFiles = useCallback((file) => {
    //filter out image files which are larger than 500KB
    if (
      (file.type === "image/jpeg" || file.type === "image/png") &&
      file.size < 524288 &&
      !isImageSet
    ) {
      openNotificationWithIcon("success", file);
      isImageSet = true;
      return true;
    } else if (
      (file.type === "video/mpeg" ||
        file.type === "video/quicktime" ||
        file.type === "video/mp4" ||
        file.type === "video/3gpp" ||
        file.type === "video/3gpp2" ||
        file.type === "video/x-ms-wmv") &&
      file.size < 5368709120 &&
      !isVideoSet
    ) {
      openNotificationWithIcon("success", file);
      isVideoSet = true;
      return true;
    } else {
      openNotificationWithIcon("error", file);
      return false;
    }
  }, []);

  return (
    <CookiesProvider>
      <CustomLayout>
        <ChunkedUploady
          destination={{ url: UrlService.uploadUrl() }}
          chunkSize={26214400}
          fileFilter={filterFiles}
        >
          <ParticipantRegistrationForm />
        </ChunkedUploady>
      </CustomLayout>
    </CookiesProvider>
  );
};

export default Index;
