import Log from "../../../src/Log";
import React, { useState } from "react";
import { CustomLayout } from "../../components/common/custom/CustomLayout";
import { Button, Space, Checkbox, Tooltip, Typography } from "antd";
import BackEndService from "../../services/BackEndService";
import Colors from "../../components/common/theme/colors";

const primaryColor = Colors.primary;
const secondaryColor = Colors.secondary;

const AdminPage = () => {
  const [currentResult, setCurrentResult] = useState("");

  const sanitizeStorage = () => {
    BackEndService.sanitizeStorage().then((response) => {
      if (response) {
        Log.d(JSON.stringify(response));
        setCurrentResult(response.data);
      } else {
        setCurrentResult(
          "Fehler Server - Response is null. Most likely a CORS Problem."
        );
      }
    });
  };

  const cleanUpStorage = () => {
    BackEndService.cleanUpStorage().then((response) => {
      if (response) {
        Log.d(JSON.stringify(response));
        setCurrentResult(response.data);
      } else {
        setCurrentResult("Fehler");
      }
    });
  };

  return (
    <CustomLayout>
      <Space>
        <Tooltip placement="topLeft" title="Sanitize Storage">
          <Button onClick={sanitizeStorage} title="Sanitize Storage">
            Sanitize Storage
          </Button>
        </Tooltip>

        <Tooltip placement="topLeft" title="CleanUp Storage">
          <Button onClick={cleanUpStorage} title="CleanUp Storage">
            CleanUp Storage
          </Button>
        </Tooltip>
      </Space>
      <p> </p>
      {/*       <p>Result: </p>
      <div dangerouslySetInnerHTML={{ __html: currentResult }}></div> */}
    </CustomLayout>
  );
};

export default AdminPage;
