import Log from "../../../src/Log";
import React, { Component, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import {
  Table,
  Button,
  Space,
  Tag,
  Checkbox,
  notification,
  Input,
  DatePicker,
  Tooltip,
  Popconfirm,
  Col,
  Row,
  Select,
} from "antd";

import BackEndService from "../../services/BackEndService";
import {
  SearchOutlined,
  SyncOutlined,
  FileExcelFilled,
  VideoCameraOutlined,
  DeleteOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment-timezone";

import Colors from "../../components/common/theme/colors";

import "moment/locale/de-at";
import locale from "antd/es/date-picker/locale/de_DE";
import UrlService from "../../services/UrlService";
const timeZone = "Europe/Berlin";

const { Option } = Select;
const primaryColor = Colors.primary;
const secondaryColor = Colors.secondary;

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const DashBoardTable = ({ onTableUpdate }) => {
  const [tableData, setTableData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contactsDefaultValue, setContactsDefaultValue] = useState([]);
  const [showSettings, setShowSettings] = useState(false);

  const [renderPicker, setRenderPicker] = useState(false);

  const dateFormat = "DD.MM.YYYY";

  const [searchText, setSearchText] = useState({});
  const [searchedColumn, setSearchedColumn] = useState({});
  const defaultRangeText = "Ganzer Zeitraum";
  const [dateRangeSelectionText, setDateRangeSelectionText] = useState(
    defaultRangeText
  );

  const defaultStartDate = new Date();
  defaultStartDate.setHours(0, 0, 0, 0);
  const defaultEndDate = new Date();
  defaultEndDate.setHours(1, 0, 0, 0);

  const defaultPickerRange: any = [
    moment(defaultStartDate, dateFormat).toDate(),
    moment(moment(defaultEndDate).add(1, "days"), dateFormat).toDate(),
  ];

  const defaultDateRange = [
    moment(new Date(2021, 0, 1, 1, 0, 0, 0), dateFormat).toDate(),
    moment(
      moment(new Date().setHours(1, 0, 0, 0)).add(1, "days"),
      dateFormat
    ).toDate(),
  ];

  const [currentSearchDateRange, setCurrentSearchDateRange] = useState(
    defaultDateRange
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    setApplicationPhaseProposalValue(applicationPhaseValue);
  };

  //will be called only once
  useEffect(() => {
    if (tableData.length <= 0) {
      getData();
    }
    getContacts();
    getApplicationPhase();
  }, []);

  useEffect(() => {
    getContacts();
  }, []);

  useEffect(() => {
    getApplicationPhase();
  }, []);

  const getContacts = () => {
    BackEndService.getContacts().then((response) => {
      Log.d(" getContacts: " + JSON.stringify(response));
      if (response && response.data) {
        Log.d(JSON.stringify(response));

        const contactsDefault = [];

        response.data.map((contact) => {
          if (contact.state == 1) {
            contactsDefault.push(contact.eMail);
          }
        });
        Log.d(contactsDefault.map((i) => "Contact: " + i + " ").join());
        setContactsDefaultValue(contactsDefault);
        setContactProposalValue(contactsDefault);
        setContacts(response.data);
      }
    });
  };

  const getApplicationPhase = () => {
    BackEndService.getApplicationPhase().then((response) => {
      Log.d(" getApplicationPhase: " + JSON.stringify(response));
      if (response && response.data && response.data[0]) {
        const value = response.data[0].applicationPhase;
        Log.d("application Phase " + value);
        setApplicationPhaseValue(value);
        setApplicationPhaseProposalValue(value);
      }
    });
  };

  const sqlToJsDate = (sqlDate) => {
    //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss")
    let sqlDateArr1 = sqlDate.split("-");
    let sYear = Number(sqlDateArr1[0]);
    let sMonth = Number(sqlDateArr1[1]) - 1;
    let sqlDateArr2 = sqlDateArr1[2].split(" ");
    let sDay = Number(sqlDateArr2[0]);
    let sqlDateArr3 = sqlDateArr2[1].split(":");
    let sHour = Number(sqlDateArr3[0]);
    let sMinute = Number(sqlDateArr3[1]);
    let sSecond = Number(sqlDateArr3[2]);

    return new Date(sYear, sMonth, sDay, sHour, sMinute, sSecond);
  };

  const getData = () => {
    BackEndService.getProductionsMetaData().then((response) => {
      Log.d(JSON.stringify(response));
      if (response) {
        let rawData = response.data;

        let jsonArray = rawData.map((dataEntry) => {
          let tempDate = sqlToJsDate(dataEntry.created_at); //new Date(dataEntry.created_at);
          dataEntry.created_at = tempDate;

          dataEntry.participantsCollection = JSON.parse(
            dataEntry.participantsCollection
          );
          if (
            dataEntry.participantsCollection !== null &&
            dataEntry.participantsCollection !== undefined
          ) {
            const arrayOfStrings: Array<string> = dataEntry.participantsCollection.map(
              (participent) => {
                if (participent !== null || participent !== undefined) {
                  let out =
                    participent.firstName +
                    " " +
                    participent.lastName +
                    " " +
                    participent.birthdate;
                  return out;
                }
              }
            );
            dataEntry.participantsCollection = arrayOfStrings;
          } else {
            dataEntry.participantsCollection = [];
          }

          dataEntry.uploadImageMetaData = JSON.parse(
            dataEntry.uploadImageMetaData
          );

          dataEntry.uploadVideoMetaData = JSON.parse(
            dataEntry.uploadVideoMetaData
          );

          return dataEntry;
        });

        setTableData(jsonArray);
        onTableUpdate(jsonArray);

        openNotificationWithIcon(
          "success",
          "Daten",
          "Daten erfolgreich vom Server geladen"
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Daten",
          `Fehler beim laden Daten vom Server ${response}`
        );
      }
    });
  };

  const openNotificationWithIcon = (type, msg, descr) => {
    notification[type]({
      message: msg,
      description: descr,
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({ searchText: selectedKeys[0] });
    setSearchedColumn({ searchedColumn: dataIndex });
  };

  const handleDateSearch = (selectedKeys, confirm, dataIndex) => {
    setDateRangeSelectionText(JSON.stringify(selectedKeys));
    confirm();
  };

  const dateLocale = "de-DE";

  const handleDateRangeChange = (
    setSelectedKeys: any,
    dates: any,
    dateStrings: any,
    info: any
  ) => {
    if (dates == null) {
      Log.d("dates is null");
      return;
    }

    Log.d(
      "dates " +
        JSON.stringify(dates) +
        "dateStrings " +
        JSON.stringify(dateStrings) +
        "info " +
        JSON.stringify(info)
    );

    if (dates[0] == null || dates[1] == null) {
      Log.d("one of the selection dates is null");
      return;
    }

    let startD = dates[0].toDate();
    //startD.setHours(1, 0, 0, 0);
    let endD = dates[1].toDate();
    //endD.setHours(24, 0, 0, 0);
    endD = moment(moment(endD.setUTCHours(23, 59, 59, 0)), dateFormat)
      .tz(timeZone)
      .toDate();
    let value = [startD, endD];

    Log.d("Set selectiondate with chagned hours " + JSON.stringify(value));

    setCurrentSearchDateRange(value);

    let concatedValues =
      startD.toLocaleDateString(dateLocale) +
      "," +
      endD.toLocaleDateString(dateLocale);

    //this is the mayor filter setting
    setSelectedKeys([concatedValues.replace(/\./g, "-")]);
  };

  const handleSearchReset = (clearFilters) => {
    if (clearFilters) {
      clearFilters();
    }
  };

  const handleDateRangeReset = (clearFilters) => {
    if (clearFilters) {
      clearFilters();
    }
    setCurrentSearchDateRange(defaultDateRange);
  };

  const getDataSetByDateRange = (searchDateRange: any) => {
    //this is not needed for the filtering in the table but for the acutal data that should be used to generate the excel
    let tempData = [];
    let start = searchDateRange[0];
    let end = searchDateRange[1];
    let check: boolean = true; //!moment.isMoment(start) && !moment.isMoment(end);

    if (check) {
      let startTime = moment(start, dateFormat).valueOf();
      let endTime = moment(end, dateFormat).valueOf();

      tableData.map((entry) => {
        let createdAtTime = moment(entry.created_at, dateFormat).valueOf();
        if (createdAtTime >= startTime && createdAtTime <= endTime) {
          tempData.push(entry);
        }
      });
    }
    return tempData;
  };

  const filterDates = (value, record) => {
    let range = value.split(",");

    let startValue = moment(range[0], dateFormat).valueOf();
    let endValue = moment(range[1], dateFormat).valueOf();
    let createdAtValue = moment(record.created_at, dateFormat).valueOf();

    return createdAtValue >= startValue && createdAtValue <= endValue;
  };

  const disabledDate = (current) => {
    // Can not select days after today and today
    return current && current > moment().endOf("day");
  };

  const getColumnDateFilterProps = (dataIndex) => ({
    filterDropdown: ({
      dataIndex,
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {renderPicker ? (
          <>
            <RangePicker
              allowClear={false}
              locale={locale}
              /*           defaultPickerValue={searchDateRange}
                      defaultValue={default} */
              value={[
                moment(currentSearchDateRange[0]),
                moment(currentSearchDateRange[1]),
              ]}
              format={dateFormat}
              disabledDate={disabledDate}
              onCalendarChange={(dates, dateStrings, info) => {
                handleDateRangeChange(
                  setSelectedKeys,
                  dates,
                  dateStrings,
                  info
                );
              }}
            />

            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleDateSearch(selectedKeys, confirm, dataIndex)
                }
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Filtern
              </Button>
              <Button
                onClick={() => handleDateRangeReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Zurück
              </Button>
            </Space>
          </>
        ) : (
          <></>
        )}
      </div>
    ),
    filterIcon: (filtered) => (
      <Tooltip placement="topLeft" title="Zeitraum auswählen">
        <SearchOutlined
          style={{ color: filtered ? secondaryColor : undefined }}
        />
      </Tooltip>
    ),
    onFilter: filterDates,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setRenderPicker(true);
      } else {
        setRenderPicker(false);
      }
    },
  });

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filtern
          </Button>
          <Button
            onClick={() => handleSearchReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Zurück
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? secondaryColor : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const sortDates = (a, b) => {
    let dA = new Date(a);
    let dB = new Date(b);
    return dA.getMilliseconds() > dB.getMilliseconds() ? 1 : -1;
  };

  const columns = [
    {
      title: "Hochgeladen am",
      dataIndex: "created_at",
      key: "created_at",
      width: 100,
      fixed: true,
      ...getColumnDateFilterProps("created_at"),
      sorter: (a, b) => sortDates(a.created_at, b.created_at),
      render: (record) => (
        <>
          <Tooltip
            placement="topLeft"
            title={`Um: ${moment(record)
              .tz(timeZone)
              .locale(dateLocale)
              .format("HH:mm:ss")}`}
          >
            {("0" + record.getDate()).slice(-2) +
              "." +
              ("0" + (record.getMonth() + 1)).slice(-2) +
              "." +
              record.getFullYear()}
          </Tooltip>
        </>
      ),
    },
    {
      title: "Titel",
      dataIndex: "productionTitle",
      key: "productionTitle",
      width: 100,
    },
    {
      title: "E-Mail (KP)",
      dataIndex: "emailMainContact",
      key: "emailMainContact",
      width: 100,
      render: (record) => (
        <>
          <Tooltip placement="topLeft" title={`Mail an ${record} senden`}>
            <a href={`mailto: ${record}`}>{record}</a>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Vorname (KP)",
      dataIndex: "firstNameMainContact",
      key: "firstNameMainContact",
      width: 100,
    },
    {
      title: "Nachname (KP)",
      dataIndex: "lastNameMainContact",
      key: "lastNameMainContact",
      width: 100,
      ...getColumnSearchProps("lastNameMainContact", "Nachname (KP)"),
      sorter: (a, b) =>
        a.lastNameMainContact.localeCompare(b.lastNameMainContact),
      render: (text) =>
        searchedColumn === "firstNameMainContact" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    },
    ,
    {
      title: "Aktionen",
      key: "action",
      width: 100,
      render: (text, record) => (
        <Space size="small">
          <Tooltip placement="topLeft" title="PDF laden">
            <Button
              type="dashed"
              icon={<FilePdfOutlined></FilePdfOutlined>}
              href={
                UrlService.storageUrl() +
                JSON.parse(record.uploadPDFMetaData).name
              }
              target="_blank"
              download
            ></Button>
          </Tooltip>
          {record.uploadVideoMetaData.isDeleted !== undefined &&
          record.uploadVideoMetaData.isDeleted === false ? (
            <>
              <Tooltip placement="topLeft" title="Video laden">
                <Button
                  type="dashed"
                  icon={<VideoCameraOutlined></VideoCameraOutlined>}
                  href={
                    UrlService.storageUrl() + record.uploadVideoMetaData.name
                  }
                  target="_blank"
                  download
                ></Button>
              </Tooltip>
              <Tooltip placement="topLeft" title="Video löschen">
                <Popconfirm
                  title="Videodatei dieser Produktion löschen?"
                  onConfirm={() => deleteVideo(record)}
                  /* onCancel={ } */
                  okText="Ja"
                  cancelText="Nein"
                >
                  <Button
                    type="dashed"
                    icon={<DeleteOutlined></DeleteOutlined>}
                    danger
                  ></Button>
                </Popconfirm>
              </Tooltip>
            </>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
  ];

  const options = {
    requestHeader: "Authorization",
    requestToken: "access_token",
  };

  let alreadyLoaded = false;

  const onReactPlayerError = (error) => {
    Log.d("onReactPlayerError" + JSON.stringify(error));
  };

  const onReactPlayerReady = (value) => {
    Log.d("onReactPlayerReady" + value);
  };
  const defaultObj = { id: 0, state: false };
  const [shouldLoadPlayer, setShouldLoadPlayer] = useState(defaultObj);
  const [currentExpandedRows, setCurrentExpandedRows] = useState([]);

  const expandable = {
    onExpand: (expanded, record) => {
      if (expanded == false && record.id == currentExpandedRows[0]) {
        setCurrentExpandedRows([]);
      }

      if (record.id != currentExpandedRows[0]) {
        setCurrentExpandedRows([record.id]);
      }

      /*       if(shouldLoadPlayer.id == record.id)
            {
              return;
            } */
      Log.d("Expanded: " + expanded + " Record: " + JSON.stringify(record));
      const curId: number = record.id;
      const expandedVal: boolean = expanded;
      const specificObj = { id: curId, state: expandedVal };
      setShouldLoadPlayer(specificObj);
    },
    onExpandedRowsChange: (expandedRows) => {
      Log.d("Expanded Rows: " + JSON.stringify(expandedRows));
    },
    expandedRowKeys: currentExpandedRows,
    expandedRowRender: (record) => (
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <p>
            <b>Interne Id:</b> {record.id}
          </p>
          <p>
            <b>Titel:</b> {record.productionTitle}
          </p>
          <b>Beschreibung:</b>
          <p>{record.productionShortSummary}</p>

          <b>Mit Hilfe:</b>
          <p>
            {record.productionParticipantHelpers === null
              ? "Nein"
              : `Ja. ${record.productionParticipantHelpers}`}
          </p>
          <p>
            <b>Filmemacher_Innen:</b>
          </p>
          <p>
            {record.participantsCollection.map((participant) => {
              let color =
                participant.length > 5 ? primaryColor : secondaryColor;
              return (
                <Tag color={color} key={participant}>
                  {participant}
                </Tag>
              );
            })}
          </p>
          <b>Team Beschreibung:</b>
          <p>
            {record.productionParticipantsDescription === null
              ? "Nein"
              : `${record.productionParticipantsDescription}`}
          </p>
          <p>
            <b>Adresse: </b>
            {` ${record.addressMainContactStreetAndNumber}, ${record.addressMainContactPostcode}, ${record.addressMainContactPlace}`}
          </p>
          {/*           <p>
            <b>Interne Jury Kommentare: </b>
          </p>
          <TextArea
            rows={4}
            placeholder={`Platz für Berwertungskommentare. (Nur eine Idee)\nJurymitglied1: Toller Film.\nJurymitglied2: Ja, die Story ist echt spannend.`}
          ></TextArea> */}
        </Col>
        <Col span={4}></Col>
        <Col span={12}>
          {shouldLoadPlayer.id === record.id &&
          shouldLoadPlayer.state === true &&
          !(
            record.uploadVideoMetaData.mime_type == "video-x-ms-asf" ||
            record.uploadVideoMetaData.mime_type == "application-octet-stream"
          ) &&
          record.uploadVideoMetaData.isDeleted !== undefined &&
          record.uploadVideoMetaData.isDeleted === false ? (
            <>
              <ReactPlayer
                muted={true}
                onError={onReactPlayerError}
                onReady={onReactPlayerReady}
                controls={true}
                url={UrlService.storageUrl() + record.uploadVideoMetaData.name}
                config={{
                  file: {
                    attributes: { crossOrigin: "anonymous" },
                  },
                }}
              />
            </>
          ) : (
            <></>
          )}

          {(record.uploadVideoMetaData.mime_type == "video-x-ms-asf" ||
            record.uploadVideoMetaData.mime_type ==
              "application-octet-stream") &&
          record.uploadVideoMetaData.isDeleted !== undefined &&
          record.uploadVideoMetaData.isDeleted === false ? (
            <>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Tag
                    color={"#ff0000"}
                  >{`Video kann nicht geladen werden.`}</Tag>
                  <Tag color={"#ff0000"}>
                    {`Mime-Type: ${record.uploadVideoMetaData.mime_type}`}
                  </Tag>
                  <Tag color={"#33aa33"}>{`Versuche es mit dem Download.`}</Tag>
                </Col>
                <Col span={4}></Col>
                <Col span={12}></Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          {record.uploadVideoMetaData.isDeleted !== undefined &&
          record.uploadVideoMetaData.isDeleted === true ? (
            <>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Tag
                    color={secondaryColor}
                  >{`Video kann nicht geladen werden.`}</Tag>
                  <Tag
                    color={secondaryColor}
                  >{`Da es bereits manuell gelöscht wurde.`}</Tag>
                </Col>
                <Col span={4}></Col>
                <Col span={12}></Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    ),
  };

  const deleteVideo = (record: any) => {
    Log.d(JSON.stringify(record));
    BackEndService.deleteVideoByProduction(record).then((response) => {
      Log.d(JSON.stringify(response));
      if (response) {
        getData();
      } else {
      }
    });
  };

  const downloadExcel = () => {
    const tempIds = [];
    Log.d("currentSearchDateRange " + JSON.stringify(currentSearchDateRange));
    const selectedTableDate = getDataSetByDateRange(currentSearchDateRange);
    Log.d("selectedTableDate " + JSON.stringify(selectedTableDate));
    selectedTableDate.map((entry) => {
      tempIds.push(entry.id);
    });

    BackEndService.getProductionsMetaDataSelectionAsExcel(tempIds).then(
      (response) => {
        Log.d(JSON.stringify(response));

        const link = document.createElement("a");
        link.href = response.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        if (response) {
        } else {
        }
      }
    );
  };

  const optionElements = [];
  for (let i = 0; i < contacts.length; i++) {
    optionElements.push(
      <Option key={contacts[i].id} value={contacts[i].eMail}>
        {contacts[i].eMail.toString()}
      </Option>
    );
  }

  const [applicationPhaseValue, setApplicationPhaseValue] = useState(0);
  const [
    applicationPhaseProsposalValue,
    setApplicationPhaseProposalValue,
  ] = useState(0);
  const [contactsProposalValue, setContactProposalValue] = useState([]);

  const handleContactsChange = (value) => {
    Log.d(`selected ${value}`);
    //temproaryily set value of selection gui
    setContactProposalValue(value);
  };

  const handleApplicationPhaseChange = (value) => {
    Log.d(
      `handleApplicationPhaseChange: ` + JSON.stringify(value.target.checked)
    );
    //temproaryily set value of selection gui
    setApplicationPhaseProposalValue(value.target.checked ? 1 : 0);
  };

  const handleSettingsSubmit = async () => {
    contacts.map((contact) => {
      //first reset to disabled
      contact.state = 0;
      Log.d("mail " + contact.eMail + " set state to 0");
      //if the value array is not empty and there is a match specifc states will be set to 1
      contactsProposalValue.filter((contactMail) => {
        if (contact.eMail === contactMail) {
          Log.d("mail " + contact.eMail + " set state to 1");
          contact.state = 1;
        }
      });
    });

    Log.d("before updateContacts: " + JSON.stringify(contacts));

    await BackEndService.updateContacts(contacts).then((response) => {
      Log.d(" updateContacts: " + JSON.stringify(response));
    });

    await BackEndService.updateApplicationPhase(
      applicationPhaseProsposalValue
    ).then((response) => {
      Log.d(" updateApplicationPhase: " + JSON.stringify(response));
    });

    //reset defaultvalue
    setContactsDefaultValue(contactsProposalValue);
    setApplicationPhaseValue(applicationPhaseProsposalValue);
    toggleSettings();
  };

  const handleSettingsCancel = () => {
    //resetToContactsDefaultValue
    setContactProposalValue(contactsDefaultValue);
    setApplicationPhaseValue(applicationPhaseValue);
    toggleSettings();
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={6}></Col>
        <Col span={6}></Col>
        <Col span={6}></Col>
        <Col span={6}>
          {Log.d("contactsDefaultValue : " + contactsDefaultValue)}
          {showSettings ? (
            <>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <p>Mail Adressen (interner Versand):</p>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Mailversand an ..."
                    defaultValue={contactsDefaultValue}
                    value={contactsProposalValue}
                    onChange={handleContactsChange}
                  >
                    {optionElements}
                  </Select>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <p>Einreichungsphase:</p>
                  <Checkbox
                    onChange={handleApplicationPhaseChange}
                    checked={applicationPhaseProsposalValue == 1 ? true : false}
                  >
                    Aktivieren
                  </Checkbox>
                </Col>
              </Row>
              <Row gutter={[2, 2]}>
                <Col span={24}>
                  <Space style={{ float: "right" }}>
                    <Popconfirm
                      title="Mit OK werden diese Einstellungen aktiviert. Bist du sicher?"
                      onConfirm={() => handleSettingsSubmit()}
                      okText="Ja"
                      cancelText="Nein"
                    >
                      <Button>OK</Button>
                    </Popconfirm>
                    <Button onClick={handleSettingsCancel}>Abbrechen</Button>
                  </Space>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Button onClick={toggleSettings} style={{ float: "right" }}>
                Einstellungen
              </Button>
            </>
          )}
        </Col>
      </Row>

      <Space>
        <Tooltip placement="topLeft" title="Daten Synchronisieren">
          <Button
            onClick={getData}
            shape="circle"
            icon={<SyncOutlined></SyncOutlined>}
          ></Button>
        </Tooltip>
        <Tooltip
          placement="topLeft"
          title={`Excel herunterladen (Zeitraum: ${dateRangeSelectionText})`}
        >
          <Button
            onClick={downloadExcel}
            shape="circle"
            icon={<FileExcelFilled></FileExcelFilled>}
          ></Button>
        </Tooltip>
      </Space>
      <Table
        expandable={expandable}
        columns={columns}
        dataSource={tableData}
        scroll={{
          x: 600,
        }}
        sticky
        rowKey="id"
      />
    </>
  );
};

export default DashBoardTable;
