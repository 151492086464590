import Log from "../../../src/Log";
import React, { useState, useEffect, useRef } from "react";
import "./PDFKitIntegration.css";
import makePDF from "./makePDF";
import { Space, Row, Col, Grid, Button } from "antd";

//FIX
import Editor from "./Editor";
/* import PDFUploader from "./PDFUploader"; */
import createUploader, { UPLOADER_EVENTS } from "@rpldy/uploader";
import UrlService from "../../services/UrlService";
import blobStream from "blob-stream";
import makePDFTest from "./makePDFTest";

const PDFDocument = require("pdfkit").default;

const lorem = "THIS IS A JOURNEY - TEST TEST TEST";

const defaultValue = makePDF
  .toString()
  .split("\n")
  .slice(1, -1)
  .join("\n")
  .replace(/^ {2}/gm, "");

const PDFKitIntegration = (props) => {
  const [value, setValue] = useState(defaultValue);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const processPDF = () => {
    setValue(defaultValue);
    setValue(value);
  };

  const uploader = createUploader({
    destination: { url: UrlService.uploadUrl() },
    autoUpload: true,
  });

  uploader.on(UPLOADER_EVENTS.ITEM_START, (item) => {
    Log.d(`item ${item.id} started uploading`);
  });

  uploader.on(UPLOADER_EVENTS.ITEM_FINISH, (item) => {
    Log.d(`item ${item.id} finished uploading`);
    let response =
      item.uploadResponse.data; /* [item.uploadResponse.length - 1].data; */
    Log.d(response);
  });

  const ref1 = useRef();
  const ref2 = useRef();

  useEffect(() => {
    if (ref1.current && ref2.current) {
      makePDFTest(PDFDocument, blobStream, lorem, ref1.current);

      new makePDF(
        PDFDocument,
        blobStream,
        ref2.current,
        props.texts,
        uploader,
        props.formValues,
        props.shouldUpload,
        props.showPreviewState
      );
    }
  }, []);

  return (
    <>
      <div>
        <Button onClick={processPDF}>Refresh</Button>
        <Row style={{ width: "100%" }} gutter={[24, 16]}>
          <Col span={12}>
            <Editor
              style={{ margin: 40, height: 1000 }}
              value={value}
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            {/*               <PDFUploader
                value={value}
                formValues={props.formValues}
                texts={props.texts}
                shouldUpload={props.shouldUpload}
                showPreview={props.showPreviewState}
              /> */}
            <iframe ref={ref1} title="pippo" width="100%" height="775" />
            <iframe ref={ref2} title="preview" width="100%" height="775" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PDFKitIntegration;
