const Colors = {
  primary: "#858794",
  primaryHover: "#37657f",
  secondary: "#879194",
  secondaryHover: "#37657f",
  transparent: "transparent",
  labelColor: "#858794",
  lightBorder: "#858794",
  inactiveField: "#f2f2f2",
  inactiveButton: "#b7dbdd",
  inactiveIcon: "#EBEBEB",
  yellow: "#fdb32a",
  yellowHover: "#F29E02",
  borderColor: "#4f8fb3",
  black: "#000000",
  white: "#ffffff",
  headingColor: "#858794",
  quoteText: "#858794",
  textColor: "#858794",
  linkColor: "#858794",
  lightGray: "#FAFBFF",
};

export default Colors;
