import Log from "../../../../src/Log";
import React from "react";
import { Circle } from "rc-progress";
import Uploady, {
  useItemProgressListener,
  useItemFinishListener,
} from "@rpldy/uploady";
import { Progress } from "antd";
import Colors from "./../../../components/common/theme/colors";

const primaryColor = Colors.primary;
const secondaryColor = Colors.secondary;

export const CustomUploadyProgress = (/* { onUploadProgressFinished } */) => {
  const [uploads, setUploads] = React.useState({});
  const progressData = useItemProgressListener();

  if (progressData && progressData.completed) {
    /* Log.d(JSON.stringify(progressData.completed)); */
    /* {(progress.length>=100)?onUploadProgressFinished(progress.length>=100):''} */

    const uploadById = uploads[progressData.id];
    const uploadByUrlOrNameAndProgress = {
      name: progressData.url || progressData.file.name,
      progress: [0],
    };
    const upload = uploadById || uploadByUrlOrNameAndProgress;

    if (!~upload.progress.indexOf(progressData.completed)) {
      upload.progress.push(progressData.completed);

      setUploads({
        ...uploads,
        [progressData.id]: upload,
      });
    }
  }

  const entries = Object.entries<{ name: String; progress: Array<number> }>(
    uploads
  );

  return (
    <div>
      {entries.map(([id, { name, progress }]) => {
        const lastProgress = progress[progress.length - 1];

        if (lastProgress < 100) {
          return (
            <div key={id}>
              {/*                                         <Circle strokeWidth={2}
                            strokeColor={lastProgress === 100 ? "#00a626" : "#2db7f5"}
                            percent={lastProgress} /> */}

              <Progress
                percent={Math.round(lastProgress)}
                status="active"
                showInfo={true}
                strokeColor={{
                  from: primaryColor,
                  to: secondaryColor,
                }}
              />
              <p>
                {/* {id} : */} {name}
              </p>
            </div>
          );
        } else {
          return (
            <div key={id}>
              <Progress
                percent={Math.round(lastProgress)}
                status="success"
                showInfo={true}
                strokeColor={{
                  from: primaryColor,
                  to: secondaryColor,
                }}
              ></Progress>
              <p>
                {/* {id} : */} {name}
              </p>
            </div>
          );
        }
      })}
    </div>
  );
};
