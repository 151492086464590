import Log from "../../../src/Log";
import React, { useCallback, useState } from "react";
import { CustomLayout } from "../../components/common/custom/CustomLayout";
import { RouteComponentProps } from "react-router-dom";
import "./_style.scss";
import AuthService from "./../../services/AuthService";
import Auth from "../../components/common/router/protected/Auth";
import CookieService from "./../../services/CookieService";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [authReference, setAuthReference] = useState(new Auth());

  /*NONLDAP   
  const handleFormSubmit = async (event: any) => {
    //event.preventDefault();
    const postData = {
      username: username,
      password: password,
    };

    const response = await AuthService.doUserLogin(postData);

    if (response) {
      AuthService.handleLoginSuccess(response, isChecked);
      //TODO OR: not sure if history still works
      props.history.push("/dashboard");
    } else {
      alert("Please check your credentials and try again");
    }
  }

  const handleChecked = () => {
    setIsChecked(!isChecked);
  } */

  const onFinish = async (values: any) => {
    Log.d("Received values of form: " + values);
    setUsername(values.username);
    setPassword(values.password);
    setIsChecked(values.checked);

    const response = await AuthService.doUserLogin(values);

    if (response) {
      AuthService.handleLoginSuccess(response, isChecked);
      //TODO OR: not sure if history still works
      gotoDashboard();
    } else {
      alert(
        "Bitte schau dir die Eingabe nochmal an. Benutzername und Passwort stimmen nicht überein."
      );
    }
  };

  const gotoDashboard = () => {
    props.history.push("/dashboard");
  };

  return (
    <>
      {!authReference.isAuthenticated() ? (
        <>
          <CustomLayout>
            {/* NON LDAP LOGIN         
            <div className="login-page">
          <div className="login-box">
            <div className="login-logo">
              <a
                href="/dashboard"
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <b>video und filmtage</b>Admin
              </a>
            </div>

            <div className="card">
              <div className="card-body login-card-body">
                <p className="login-box-msg">Sign in to start your session</p>

                <form onSubmit={(event) => handleFormSubmit(event)}>
                  <div className="input-group mb-3">
                    <input
                      type="email"
                      name="name"
                      className="form-control"
                      placeholder="Email"
                      value={username}
                      onChange={(event) =>
                        setUsername(event.target.value) 
                      }
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(event) =>
                        setPassword(event.target.value)
                      }
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="icheck-primary">
                        <input
                          type="checkbox"
                          id="remember"
                          onChange={() => handleChecked()}
                          checked={isChecked}
                        />
                        <label
                          onClick={() => handleChecked()}
                          id="remember-label"
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </form>

                <p className="mb-1">
                  <a href="forgot-password.html">I forgot my password</a>
                </p>
                <p className="mb-0">
                  <a href="register.html" className="text-center">
                    Register a new membership
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div> */}
            <div className="login-form-container">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Bitte gib deinen Benutzernamen ein!",
                    },
                  ]}
                >
                  <Input
                    onKeyDown={(e) =>
                      e.keyCode == 13 ? e.preventDefault() : ""
                    }
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Benutzername"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Bitte gib dein Passwort ein!" },
                  ]}
                >
                  <Input
                    onKeyDown={(e) =>
                      e.keyCode == 13 ? e.preventDefault() : ""
                    }
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Passwort"
                  />
                </Form.Item>
                <Form.Item>
                  {/*                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Anmeldung speichern?</Checkbox>
                  </Form.Item> */}

                  {/*               <a className="login-form-forgot" href="">
                Passwort vergessen?
              </a> */}
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Anmelden
                  </Button>
                  {/* Or <a href="">register now!</a> */}
                </Form.Item>
              </Form>
            </div>
          </CustomLayout>
        </>
      ) : (
        <>{/*       {gotoDashboard()} */}</>
      )}
    </>
  );
};

export default Login;
