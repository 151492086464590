import React from "react";
import PDFKitIntegration from "./PDFKitIntegration";
import { CustomLayout } from "../../components/common/custom/CustomLayout";

import {
  validateMessagesDE,
  generalTextsDE,
} from "../../assets/localizedStrings";

const allFormValues = {
  productionTitle: "Die unwahrscheinliche komplexität des Seins.",
  participantsCollection: [
    { firstName: "Jim", lastName: "Jarmush", birthdate: 12 },
    { firstName: "Ethan", lastName: "Cohen", birthdate: 15 },
    { firstName: "Sofia", lastName: "Coppola", birthdate: 12 },
    { firstName: "Marvin", lastName: "Kren", birthdate: 20 },
  ],
  firstNameMainContact: "Oliver",
  lastNameMainContact: "Rudoll",
  nameMainContact:
    '{"firstNameMainContact":"Oliver","lastNameMainContact":"Rudoll"}',
  addressMainContactStreetAndNumber: "Blaugsichtgasse 44 / 3 / 2",
  addressMainContactPostcode: "1010",
  addressMainContactPlace: "Wien",
  addressMainContactCountry: "Österreich",
  addressMainContact:
    '{"addressMainContactStreetAndNumber":"Blaugsichtgasse 44 / 3 / 2","addressMainContactPostcode":"1010","addressMainContactPlace":"Wien","addressMainContactCountry":"Österreich"}',
  prefix: "+43",
  phoneMainContact: "555889977",
  emailMainContact: "oliver.rudoll@wienxtra.at",
  productionLength: "2 Wochen",
  productionTime: "ca. 3 Monate",
  productionShortSummary:
    'The final story, "Lost in Space", introduces Johnny (Joe Strummer). Upset after losing his job and his girlfriend (Dee Dee), Johnny – called Elvis, much to his chagrin.',
  productionParticipantsDescription:
    "Die Zusammenarbeit von Jim Jarmusch und Neil Young führte 1995 nicht nur zum betörenden Soundtrack von Dead Man, sondern zwei Jahre später auch zu einem aussergewöhnlichen Musikfilm.",
  newLetterAgreement: true,
  conditionsOfParticipation: true,
  uploadImageMetaData: {
    path: "upload/productions/",
    name: "Signature_of_BTS'_Jungkook_151c8def3c528f9005bd3b8802f28381.png",
    mime_type: "image-png",
  },
  uploadVideoMetaData: {
    path: "upload/productions/",
    name: "sample-mov-file_151c8def3c528f9005bd3b8802f28381.mov",
    mime_type: "video-quicktime",
  },
};

const Index = (props) => {
  return (
    <>
      <CustomLayout>
        <PDFKitIntegration
          formValues={allFormValues}
          texts={generalTextsDE}
          shouldUpload={false}
          showPreviewState={true}
        />
      </CustomLayout>
    </>
  );
};

export default Index;
