import React, { useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import NavbarMobile from "./NavbarMobile";
import stadtWienLogo from "../../../assets/wienXtra/img/SW_MA13-neu-orbit1_pos_rgb.png";
import { DrawerProvider } from "../reusable/DrawerContext";
import { Layout, BackTop, Image, Row, Col } from "antd";
const { Header, Content, Footer } = Layout;

const propTypes = {
  children: PropTypes.node,
};
const defaultProps = {
  children: "",
};

export const CustomLayout = ({ children }) => {
  return (
    <>
      <DrawerProvider>
        <NavbarMobile />
        <Layout>
          <Layout>
            {/*             <Header className="site-layout-sub-header-background">
            </Header> */}
            <Content className="site-content">
              <div className="site-layout-background">{children}</div>
            </Content>
          </Layout>
          <Footer className="footer-content">
            <Row>
              <Col>
                <Image src={stadtWienLogo} className="footerImage"></Image>
              </Col>
            </Row>
            <Row className="Footer-text">
              <Col>WIENXTRA ©2022</Col>
            </Row>
          </Footer>
          <BackTop visibilityHeight={10} />
        </Layout>
      </DrawerProvider>
    </>
  );
};

CustomLayout.propTypes = propTypes;
CustomLayout.defaultProps = defaultProps;
