import Log from "../../src/Log";
import axios from "axios";
import CookieService from "./CookieService";
const path = require("path");
const envPath = path.resolve(__dirname, "client/.env");
require("dotenv").config({ path: envPath });

class HttpService {
  async get(url) {
    const at = CookieService.get("access_token");
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + at,
      },
    };

    Log.d("get optiopns: " + JSON.stringify(options));

    Log.d("envpath: " + envPath);

    try {
      return await axios.get(url, options);
    } catch (error) {
      console.error("Not able to fetch data", error);
    }
  }

  async post(url, data, options = null, acceptType = "application/json") {
    const at = CookieService.get("access_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: acceptType,
      apiKey: process.env.REACT_APP_BACKENDAPIKEY,
      Authorization: "Bearer " + at,
    };

    const postOptions = {
      headers: headers,
    };

    const finalOptions = Object.assign(postOptions, options);

    try {
      return await axios.post(url, data, finalOptions);
    } catch (error) {
      console.error("Not able to fetch data", error);
      return error.response !== undefined ? error.response : error;
    }
  }
}

export default new HttpService();
